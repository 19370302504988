html, body, main, .main-contain { width: 100%; height: 100%; background-color: #F0F2F5; overscroll-behavior-y: none;}
.sidebar-container{
  z-index: 9999;
}
.topbar-container{
  width: calc(100vw - 50px);
  z-index: 9998;
}
.topbar-container-collapsed{
  width: calc(100vw - 195px) !important;
}
.body-container{
  margin-top: 65px;
  margin-left: 40px;
}
.body-container-collapsed {
  margin-left: 180px !important;
}
.authenticating-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 5rem;
  background: #fff;
  opacity: 0.8;
  animation: pulsate 0.4s infinite alternate linear;
}

@keyframes pulsate {
  from { opacity: 1.0; }
  to { opacity: 0.2; }
}

.pre {
  white-space: pre;
}

.break-all {
  word-break: break-all;
}

.table-props tr th {
  width: 1px;
}
#case-list-container form {
  float: left;
  width: 383px;
  margin-top: 15px;
  margin-left: 20px;
}
#case-list-container input {
  width: 333px;
  background-color: #f6f6f6;
  padding-left: 50px;
  border-radius: 8px;
  border: none;
  margin: 6px 0 0 20px;
  height: 33px;
  font-size: 11pt;
}
#case-list-container ::placeholder {
  color: #d3d3d3;
  opacity: 1;
}
#case-list-container .fa-search {
  position: absolute;
  top: 33px;
  left: 60px;
  color: #202020;
  font-size: 10pt;
}
#case-list-container #filter {
  float: right;
  margin-right: 14px;
  margin-top: 20.5px;
  border: 0.5px solid #c4c4c4;
  width: 33px;
  border-radius: 4px;
  height: 33px;
}
#case-list-container #filter > img {
  width: 16.11px;
  height: auto;
  margin: 9px auto 10px auto;
  display: block;
}
#case-list-container #filter:hover {
  cursor: pointer;
}
#case-list-container #filter-options {
  position: absolute;
  top: 60px;
  right: 155px;
  border: 0.5px solid #c4c4c4;
  width: 120px;
  height: 132.5px;
  text-align: left;
  background-color: white;
  z-index: 1;
  border-radius: 4px;
}
#case-list-container #filter-options > ul {
  width: 100%;
  height: 100%;
}
#case-list-container #filter-options > ul > li {
  padding-left: 10px;
  width: calc(100% - 10px);
  height: 20px;
  padding-top: 6.5px;
  padding-bottom: 6.5px;
  font: 400 15px 'Open Sans';
}
#case-list-container #filter-options > ul > li:hover {
  cursor: pointer;
  background-color: #f1f1f1;
}
#case-list-container #sort-options {
  position: absolute;
  top: 60px;
  right: 19px;
  border: 0.5px solid #c4c4c4;
  width: 205px;
  height: 165.2px;
  text-align: left;
  background-color: white;
  z-index: 1;
  border-radius: 4px;
}
#case-list-container #sort-options > ul {
  width: 100%;
  height: 100%;
}
#case-list-container #sort-options > ul > li {
  padding-left: 10px;
  width: calc(100% - 10px);
  height: 20px;
  padding-top: 6.5px;
  padding-bottom: 6.5px;
  font: 400 15px 'Open Sans';
}
#case-list-container #sort-options > ul > li:hover {
  cursor: pointer;
  background-color: #f1f1f1;
}
#case-list-container #sort {
  float: right;
  margin-top: 20.5px;
  margin-right: 20px;
  font: 400 15px 'Open Sans';
  border: 0.5px solid #c4c4c4;
  width: 120px;
  border-radius: 4px;
  height: 33px;
}
#case-list-container #sort > p {
  position: relative;
  top: 5px;
  left: 12px;
}
#case-list-container #sort:hover {
  cursor: pointer;
}
#case-list-container #titles {
  width: 100%;
  position: absolute;
  display: flex;
  top: 80px;
  left: 0px;
}
#case-list-container #titles > li:first-child {
  margin-left: 65px;
  width: 17vw;
}
#case-list-container #titles > li:nth-child(4) {
  width: 8vw;
}
#case-list-container #titles > li:nth-child(6) {
  width: 6vw;
}
#case-list-container #titles > li {
  width: 11vw;
  font: 400 14px 'Open Sans';
  color: #003a68;
}
#case-list-container #case-list {
  width: 100%;
  position: absolute;
  top: 115px;
  height: 80%;
}
#case-list-container #case-list > ul > li:first-child {
  border-top: none;
}
#case-list-container #case-list > ul > li {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid #ddd;
  padding-top: 0.55vh;
  padding-bottom: 0.5vh;
}
#case-list-container #case-list > ul > li > div:first-child {
  margin-left: 65px;
  width: 17vw;
}
#case-list-container #case-list > ul > li > div:nth-child(4) {
  width: 8vw;
}
#case-list-container #case-list > ul > li > div:nth-child(6) {
  width: 6vw;
}
#case-list-container #case-list > ul > li > div {
  font: 300 14px 'Open Sans';
  width: 11vw;
}
#case-list-container #case-list > ul > li > div > a {
  text-decoration: underline;
  color: #0075a0;
}
#case-list-container #case-list > ul > li > #assigned {
  display: flex;
}
#case-list-container #case-list > ul > li > #assigned > div {
  width: 25px;
}
#case-list-container #case-list > ul > li > #assigned > div > img {
  width: 20px;
}
#case-list-container #case-list > ul > li > #progress > div:first-child {
  margin-top: 12px;
  background-color: #00b5d5 31;
  height: 6px;
  border-radius: 20px;
  width: 111px;
}
#case-list-container #case-list > ul > li > #progress > div:first-child > #progressBar {
  background: transparent linear-gradient(270deg, #00c9c2 0%, #00b6d5 62%, #0096bb 100%) 0% 0% no-repeat padding-box;
  height: 6px;
  border-radius: 20px;
}
#case-list-container #case-list #controls {
  position: absolute;
  left: 50%;
  top: 87%;
  transform: translateX(-50%);
}
.card-container {
  border: solid .5px #d1d1d1;
  border-radius: 4px;
  box-shadow: 0px 3px 7px #0000000F;
  -moz-box-shadow: 0px 3px 7px #0000000F;
  -webkit-box-shadow: 0px 3px 7px #0000000F;
  -o-box-shadow: 0px 3px 7px #0000000F;
  background-color: white;
  padding: 15px;
}
@media screen and (min-width: 1600px) {
  #case-list-container #titles > li {
    font-size: 19px !important;
 }
  #case-list-container #case-list > ul > li {
    padding-top: 1.2vh !important;
    padding-bottom: 1.2vh !important;
 }
  #case-list-container #case-list > ul > li > div {
    font-size: 19px !important;
 }
  #case-list-container #controls {
    top: 95% !important;
 }
}
#case-list-container-two {
  height: 100%;
}
#case-list-container-two > #top-section {
  height: 7.5vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
#case-list-container-two > #top-section > h6 {
  margin-left: 1.7vw;
  position: relative;
  bottom: 13px;
}
#case-list-container-two > #top-section > #top-sub {
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 0.7vw;
  position: relative;
  bottom: 13px;
}
#case-list-container-two > #top-section > #top-sub > button {
  margin-left: 0.5vw;
  background-color: #81d36b;
  color: white;
  border: none;
  border-radius: 4px;
  font: 400 14px 'Open Sans';
  width: 95px;
  height: 24px;
}
#case-list-container-two > #top-section > #top-sub > button:hover {
  background-color: #60c744;
}
#case-list-container-two > #top-section > #top-sub form {
  width: 171px;
  height: 24px;
}
#case-list-container-two > #top-section > #top-sub input {
  width: 141px;
  background-color: #f6f6f6;
  padding-left: 30px;
  border-radius: 4px;
  border: none;
  height: 24px;
  font-size: 12px;
}
#case-list-container-two > #top-section > #top-sub ::placeholder {
  color: #d3d3d3;
  opacity: 1;
}
#case-list-container-two > #top-section > #top-sub .fa-search {
  position: relative;
  bottom: 20px;
  left: 10px;
  color: #d3d3d3;
  font-size: 10px;
  top: -23px;
}
#case-list-container-two > #top-section > #top-sub #filter {
  border: 0.5px solid #c4c4c4;
  margin-left: 0.5vw;
  width: 24px;
  border-radius: 4px;
  height: 24px;
}
#case-list-container-two > #top-section > #top-sub #filter > img {
  width: 11.89px;
  height: auto;
  margin: 7px auto 5px auto;
  display: block;
}
#case-list-container-two > #top-section > #top-sub #filter:hover {
  cursor: pointer;
}
#case-list-container-two > #top-section > #top-sub #filter-options-home {
  position: absolute;
  top: 8vh;
  right: 3.6vw;
  border: 0.5px solid #c4c4c4;
  width: 120px;
  height: 132.5px;
  text-align: left;
  background-color: white;
  z-index: 1;
  border-radius: 4px;
}
#case-list-container-two > #top-section > #top-sub #filter-options-home > ul {
  width: 100%;
  height: 100%;
}
#case-list-container-two > #top-section > #top-sub #filter-options-home > ul > li {
  padding-left: 10px;
  width: calc(100% - 10px);
  height: 20px;
  padding-top: 6.5px;
  padding-bottom: 6.5px;
  font: 400 15px 'Open Sans';
}
#case-list-container-two > #top-section > #top-sub #filter-options-home > ul > li:hover {
  cursor: pointer;
  background-color: #f1f1f1;
}
#case-list-container-two > #top-section > #top-sub #sort:hover {
  cursor: pointer;
}
#case-list-container-two > #top-section > #top-sub #sort-options-home {
  position: absolute;
  top: 8vh;
  right: 10vw;
  border: 0.5px solid #c4c4c4;
  width: 205px;
  height: 165.2px;
  text-align: left;
  background-color: white;
  z-index: 1;
  border-radius: 4px;
}
#case-list-container-two > #top-section > #top-sub #sort-options-home > ul {
  width: 100%;
  height: 100%;
}
#case-list-container-two > #top-section > #top-sub #sort-options-home > ul > li {
  padding-left: 10px;
  width: calc(100% - 10px);
  height: 20px;
  padding-top: 6.5px;
  padding-bottom: 6.5px;
  font: 400 15px 'Open Sans';
}
#case-list-container-two > #top-section > #top-sub #sort-options-home > ul > li:hover {
  cursor: pointer;
  background-color: #f1f1f1;
}
#case-list-container-two > #top-section > #top-sub #sort {
  margin-left: 0.5vw;
  font: 400 12px 'Open Sans';
  border: 0.5px solid #c4c4c4;
  width: 89px;
  border-radius: 4px;
  height: 24px;
}
#case-list-container-two > #top-section > #top-sub #sort > p {
  position: relative;
  top: 4px;
  left: 9px;
}
#case-list-container-two #titles {
  width: 100%;
  position: relative;
  display: flex;
}
#case-list-container-two #titles > li:first-child {
  margin-left: 30px;
  width: 19vw;
}
#case-list-container-two #titles > li:nth-child(3) {
  width: 13vw;
}
#case-list-container-two #titles > li:nth-child(4) {
  width: 8vw;
}
#case-list-container-two #titles > li:nth-child(5) {
  width: 8vw;
}
#case-list-container-two #titles > li {
  width: 12vw;
  font: 400 12px 'Open Sans';
  color: #003a68;
}
#case-list-container-two #case-list {
  width: 100%;
  position: relative;
  top: 1vh;
  height: 72%;
}
#case-list-container-two #case-list > ul > li:first-child {
  border-top: none;
}
#case-list-container-two #case-list > ul > li {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #ddd;
  padding-top: 0.3vh;
  padding-bottom: 0.3vh;
}
#case-list-container-two #case-list > ul > li > div:first-child {
  margin-left: 30px;
  width: 19vw;
}
#case-list-container-two #case-list > ul > li > div:nth-child(4) {
  width: 8vw;
}
#case-list-container-two #case-list > ul > li > div:nth-child(5) {
  width: 8vw;
}
#case-list-container-two #case-list > ul > li > div {
  font: 300 12px 'Open Sans';
  width: 12vw;
}
#case-list-container-two #case-list > ul > li > div > a {
  text-decoration: underline;
  color: #0075a0;
}
#case-list-container-two #case-list > ul > li > #assignedHome {
  display: flex;
}
#case-list-container-two #case-list > ul > li > #assignedHome > div {
  width: 19px;
}
#case-list-container-two #case-list > ul > li > #assignedHome > div > img {
  width: 17px;
}
#case-list-container-two #case-list > ul > li > #progress {
  width: 13vw;
}
#case-list-container-two #case-list > ul > li > #progress > div:first-child {
  margin-top: 6px;
  background-color: #00b5d5 31;
  height: 5px;
  border-radius: 20px;
  width: 111px;
}
#case-list-container-two #case-list > ul > li > #progress > div:first-child > #progressBar {
  background: transparent linear-gradient(270deg, #00c9c2 0%, #00b6d5 62%, #0096bb 100%) 0% 0% no-repeat padding-box;
  height: 5px;
  border-radius: 20px;
}
#case-list-container-two #case-list #controlsHome {
  position: absolute;
  left: 50%;
  top: 99.9%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 1600px) {
  #case-list-container-two #titles > li {
    font-size: 17px !important;
 }
  #case-list-container-two #case-list > ul > li {
    padding-top: 0.5vh !important;
    padding-bottom: 0.5vh !important;
 }
  #case-list-container-two #case-list > ul > li > div {
    font-size: 17px !important;
 }
}

#task-container {
  height: calc(100% - 40px);
  width: calc(100% - 75px);
  position: absolute;
  bottom: 0;
  left: 75px;
}
#task-container > #header {
  display: flex;
  justify-content: space-between;
  position: absolute;
  align-items: center;
  width: 70vw;
  left: 40%;
  top: 10%;
  transform: translate(-50%, -50%);
}
#task-container > #header > div > h6 {
  padding-bottom: 13px;
}
#task-container > #header > button {
  -webkit-box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.25);
  height: 37px;
  width: 180px;
  background-color: #81d36b;
  color: white;
  border: none;
  border-radius: 6px;
  position: relative;
  top: 3.5vh;
  font-size: 14px !important;
}
#task-container > #header > button:hover {
  background-color: #60c744;
}
#task-container #task-list-container {
  background-color: white;
  position: absolute;
  border-radius: 6px;
  -webkit-box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.25);
  width: 70vw;
  left: 40%;
  top: 55.5%;
  transform: translate(-50%, -50%);
  height: 71vh;
  display: flex;
  flex-direction: column;
}
#task-container #task-list-container > #top {
  display: flex;
  justify-content: space-between;
  margin-left: 2.5vw;
  margin-right: 2.5vw;
  margin-top: 2vh;
}
#task-container #task-list-container > #top > #top-left {
  display: flex;
  flex-direction: row-reverse;
}
#task-container #task-list-container > #titles {
  display: flex;
  font-family: 'Open Sans';
  font-size: 14px;
  color: #003a68;
  margin-left: 4vw;
  margin-top: 3vh;
}
#task-container #task-list-container > #titles > li {
  width: 14vw;
}
#task-container #task-list-container > #titles > li:first-child {
  width: 22vw;
}
#task-container #task-list-container > #titles > li:nth-child(4) {
  width: 11vw;
}
#task-container #task-list-container > #titles > li:last-child {
  width: 18vw;
}
#task-container form {
  width: 382.89px;
}
#task-container input {
  width: 337.89px;
  background-color: #f6f6f6;
  padding-left: 35px;
  border-radius: 8px;
  border: none;
  height: 30px;
  font-size: 11pt;
}
#task-container ::placeholder {
  color: #d3d3d3;
  opacity: 1;
}
#task-container .fa-search {
  color: #202020;
  width: 12px;
  position: absolute;
  margin-left: 12px;
  margin-top: 11px;
}
#task-container #sort {
  font: 400 14px 'Open Sans';
  border: 0.5px solid #c4c4c4;
  width: 120px;
  border-radius: 4px;
  height: 30px;
}
#task-container #sort > p {
  position: relative;
  top: 7px;
  left: 12px;
}
#task-container #sort:hover {
  cursor: pointer !important;
}
#task-container #sort-options {
  position: absolute;
  right: 50px;
  border: 0.5px solid #c4c4c4;
  width: 205px;
  text-align: left;
  background-color: white;
  z-index: 1;
  border-radius: 4px;
}
#task-container #sort-options > ul {
  width: 100%;
  height: 100%;
}
#task-container #sort-options > ul > li {
  padding-left: 10px;
  width: calc(100% - 10px);
  height: 20px;
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
  font: 15px 'Open Sans';
}
#task-container #sort-options > ul > li:hover {
  cursor: pointer;
  background-color: #f1f1f1;
}
#task-container #filter {
  border: 0.5px solid #c4c4c4;
  width: 30px;
  border-radius: 4px;
  height: 30px;
  margin-right: 10px;
}
#task-container #filter > img {
  width: 14.11px;
  height: auto;
  margin: 8px auto 10px auto;
  display: block;
}
#task-container #filter:hover {
  cursor: pointer !important;
}
#task-container #filter-options {
  position: absolute;
  border: 0.5px solid #c4c4c4;
  width: 120px;
  text-align: left;
  background-color: white;
  z-index: 1;
  border-radius: 4px;
}
#task-container #filter-options > ul {
  width: 100%;
  height: 100%;
}
#task-container #filter-options > ul > li {
  padding-left: 10px;
  width: calc(100% - 10px);
  height: 20px;
  padding-top: 6.5px;
  padding-bottom: 6.5px;
  font: 400 15px 'Open Sans';
}
#task-container #filter-options > ul > li:hover {
  cursor: pointer;
  background-color: #f1f1f1;
}
#task-container #case-list ul {
  margin-top: 1.5vh;
  font-size: 14px;
  font-family: 'Open Sans';
  font-weight: 300;
}
#task-container #case-list ul li {
  display: flex;
  border-top: 1px solid #ddd;
  padding-top: 1.48vh;
  padding-bottom: 1.48vh;
}
#task-container #case-list ul li > div {
  width: 14vw;
}
#task-container #case-list ul li > div > a {
  text-decoration: underline;
  color: #0075a0;
}
#task-container #case-list ul li > div:first-child {
  width: 22vw;
  margin-left: 4vw;
}
#task-container #case-list ul li > div:nth-child(4) {
  width: 11vw;
}
#task-container #case-list ul li > div:last-child {
  width: 18vw;
}
#task-container #case-list ul li:first-child {
  border-top: none;
}
#task-container #case-list #controls {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: calc(100% - 3vh);
  transform: translate(-50%, -50%);
}
#chart-container {
  display: flex !important;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  right: 0;
  bottom: 0;
  height: calc(100% - 40px);
  width: 35vh;
  background-color: #e4e4ea;
}
#chart-container > div {
  height: 30%;
  width: 94%;
}
#chart-container > div > p {
  font: 18px 'Lato';
}
#chart-container > div > div {
  margin-top: 10px;
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 100%;
  height: 90%;
}
#chart-container > div:first-child {
  height: 27%;
}
#chart-container > div:last-child {
  height: 22%;
}
#chart-container > div:last-child > div {
  height: 100%;
  display: flex;
  flex-direction: column;
}
#chart-container > div:last-child > div > div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
#chart-container > div:last-child > div > div > div {
  width: 48%;
  height: 92%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
#chart-container > div:last-child > div > div > div > p:first-child {
  text-transform: uppercase;
  font: 600 14px 'Open Sans';
  margin-top: 5px;
}
#chart-container > div:last-child > div > div > div > p:last-child {
  font: 700 25px 'lato';
  margin-top: 5px;
}
#complete {
  background: #bed758 34 0% 0% no-repeat padding-box;
  border-radius: 3px;
}
#complete > p {
  color: #bed758;
}
#review {
  background: #81d36b 42 0% 0% no-repeat padding-box;
  border-radius: 3px;
}
#review > p {
  color: #81d36b;
}
#to-do {
  background: #003968 2d 0% 0% no-repeat padding-box;
  border-radius: 3px;
}
#to-do > p {
  color: #003a68;
}
#progress {
  background: #01b3db 31 0% 0% no-repeat padding-box;
  border-radius: 3px;
}
#progress > p {
  color: #01b3db;
}
@media screen and (min-width: 1600px) {
  #header {
    top: 11.8% !important;
 }
  #titles > li {
    font-size: 19px !important;
 }
  #case-list > ul > li {
    padding-top: 1.4vh !important;
    padding-bottom: 1.4vh !important;
 }
  #case-list > ul > li > div {
    font-size: 19px !important;
 }
  .box > p:first-child {
    font: 600 19px 'Open Sans' !important;
    margin-top: 10px !important;
 }
  .box > p:last-child {
    font: 700 30px 'lato' !important;
    margin-top: 10px !important;
 }
}
